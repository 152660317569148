.ui.modal > .close {
  top: 0.5rem;
  right: 0.5rem;
  color: #8798ad;
}

.ui.secondary.pointing.menu {
  border-bottom: none;
}

.tableHeader {
  text-align: center;
  font-weight: bold;
  white-space: pre-line !important;
  word-wrap: break-word;
  background: #0ac2e4;
  color: white;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: #f0f0f0;
}

.MuiPaper-root {
  font-size: 20px;
  width: 350px !important;
}
